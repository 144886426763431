import palette from '../palette';

export default {
    colorbar: {
        background: palette.blackPearl,
        height: 5
    },

    headerTitleFontWeight: 'normal',

    pageHeaderTitle: {
        color: palette.white
    }, 

    headerTitleDark: {
        background: palette.astronautBlue,
        color: palette.white
    },

    headerTitleSmb: {
        background: palette.astronautBlue,
        color: palette.white
    },

    headerTotalsSmb: {
        background: palette.astronautBlue,
        color: palette.white
    },

    headerTotalsDark: {
        background: palette.astronautBlue,
        color: palette.white
    },

    backNavBackground: {
        background: palette.astronautBlue,
    },

    backNavBackgroundDark: {
        background: palette.astronautBlue,
    },

    backNavIconColorDark: palette.white,

    backNavSmbIconColor: palette.white,

    summaryFooter: {
        backgroundColor: palette.blackPearl,
        color: palette.silver
    },

    footerButtonPrimary: {
        background: palette.cerulean,
        borderColor: palette.cerulean,
        borderRadius: 4,
        color: palette.white,
        '&:hover, &:focus': {
            background: palette.royalBlue,
            borderColor: palette.royalBlue,
            color: palette.white
        },
        '&.disabled': {
            color: palette.white,
            background: palette.peppermint
        }
    },

    footerButtonSecondary: {
        background: palette.white,
        borderColor: palette.white,
        color: palette.astronautBlue,
        '&:hover, &:focus': {
            background: palette.astronautBlue,
            borderColor: palette.astronautBlue,
            color: palette.white
        },
        '&.disabled': {
            background: palette.gallery,
            color: palette.mineShaft
        }
    }
};