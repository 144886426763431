import palette from '../palette';

export default {
    colorbar: {
        background: palette.blackPearl,
        height: 5 
    },

    headerTitleFontWeight: 'normal',
  
    headerTitle: {
        color: palette.white
    },

    headerTitleDark: {
        background: palette.astronautBlue,
        color: palette.white
    },

    headerTitleSmb: {
        background: palette.astronautBlue,
        color: palette.white
    },

    pageHeaderTitle: {
        color: palette.white
    },
  
    headerTotals: {
        background: palette.astronautBlue,
        color: palette.white
    },

    headerTotalsDark: {
        background: palette.astronautBlue,
        color: palette.white
    },
    
    headerTotalsSmb: {
        background: palette.astronautBlue,
        color: palette.white
    },
  
    backNavBackground: {
        background: palette.astronautBlue,
    },

    backNavBackgroundDark: {
        background: palette.astronautBlue,
    },

    backNavIconColor: palette.white,
    backNavIconColorDark: palette.white,

    backNavSmbBackground: {
        background: palette.astronautBlue,
    },
    backNavSmbIconColor: palette.white,
  };