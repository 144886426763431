import palette from '../palette';

export default {
  summaryFooter: {
    backgroundColor: palette.blackPearl,
    color: palette.white
  },
 
  footerButton: {
    borderColor: palette.cerulean,

    '&:hover': {
      borderColor: palette.royalBlue,
    }
  },

  footerButtonPrimary: {
    background: palette.cerulean,
    borderColor: palette.cerulean,
    color: palette.white,

    '&:hover, &:focus': {
      background: palette.royalBlue,
      borderColor: palette.royalBlue,
      color: palette.white
    },
    '&.disabled': {
      background: palette.peppermint,
      color: palette.white,
    }
  },
  footerButtonSecondary: {
    background: palette.white,
    borderColor: palette.white,
    color: palette.astronautBlue,

    '&:hover, &:focus': {
      background: palette.astronautBlue,
      borderColor: palette.astronautBlue,
      color: palette.white
    },
    '&.disabled': {
      background: palette.gallery,
      color: palette.mineShaft
    }
  }
};