import { generateUrl, serviceBase, serviceBases } from '@dbiqe/common';

/*
 * urls that can be either admin or client
 * and share the same path
 */
const dynamicUrls = {
    login: '/api/security/login',
    logout: '/api/security/logout',
    logoutGet: '/api/security/logoutGet',
    userInfo: '/api/security/user',
    features: '/api/security/forgottenPasswordFeature',
    tableMaintSaveData: '/api/tableMaint/saveData',
    tableMaintRemoveData: '/api/tableMaint/removeData',
    tableMaintBulkRemoveData: '/api/tableMaint/bulkRemoveData',
    tableMaintData: '/api/tableMaint/getData',
    tableMaintAdd: '/api/tableMaint/add',
    inquirySavedViews: '/api/inquiry/getSavedViews',
    inquiryData: '/api/inquiry/getData',
    inquiryHeaderData: '/api/inquiry/getHeaderData',
    inquiryRowsData: '/api/inquiry/getRowsData',
    inquiryQueryResults: '/api/inquiry/getQueryResults',
    runReport: '/api/jasperReports/runReport',
    runAsyncJasperReport: '/api/reporting/executeReport',
    runAsyncAuditHistoryReport: '/api/payment/executeAuditHistoryReport',
    runAsyncListViewReport: '/api/reporting/executeListView',
    runAsyncDetailAuditViewReport: '/api/reporting/executeDetailAuditView',
    runAsyncPassthruDetailAuditViewReport: '/api/reporting/executePassthruDetailAuditView',
    runAsyncLegacyReport: '/api/downloadLegacyReports/legacyReportData',
    reportData: '/api/jasperReports/getReportData',
    downloadReport: '/api/downloadReports/download',
    downloadAsyncReport: '/api/reportDownload/retrieveReport',
    sbPaymentTypeList: '/api/smbMenu/',
    // change password when user is in the application (there's a valid session)
    changePassword: '/api/userSelfService/changePassword',
    // reset password change initiated at the login page or from a page initiated by email link
    resetPasswordLoginPage: '/api/userSelfService/resetPassword',
    validateEmailResetPasswordRequest: '/api/forgottenPassword/validateEmailResetPasswordRequest',
    mySettingsUpdate: '/api/userSelfService/mySettings/updateMySettings',
    profile: '/api/menu',
    entitledActions: '/api/accessService/getEntitledActions',
    wsrapper: '/wsWrapper.do',
    localization: '/api/localizationService/',
    localizationAllResources: '/api/localizationService/getAllResources',
    localizationLoginResources: '/api/localizationService/getLoginResources',
    sbDates: '/api/date/list',
    userCentric: '/api/users/userCentric/',
    userCentricV2: '/api/users/userCentric/v2/',
    roleMaint: '/api/roleMaintenance/role/',
    readAdministeredData: '/api/adminPayment/listView/payments/read',
    beneAddressBook: '/api/beneAddressBook/read',
    beneAddressBookPaymentDetail: '/api/beneAddressBook/paymentOperation/GETPAYMENTDETAILINFO',
    beneAddressBookFreeFormInfo: '/api/beneAddressBook/paymentOperation/getFreeFormInfo',
    beneAddressBookConfigInfo: '/api/beneAddressBook/paymentOperation/getPaymentConfigInfo',
    beneAddressBookAction: '/api/beneAddressBook/',
    beneAddressBankInfo: '/api/beneAddressBook/paymentOperation/GETPAYMENTDETAILINFOBANKCODE',
    beneAddressCurrencyDetail: '/api/beneAddressBook/paymentOperation/GETPAYMENTTYPEINFO',
    beneAddressGetChildren: '/api/beneAddressBook/paymentOperation/GETCHILDREN',
    tableMaintenance: '/api/tableMaintenance',
    challengeQuestions: '/api/forgottenPassword/getRandomChallengeQuestions',
    forgottenPassword: '/api/forgottenPassword/validateQAChallenge',
    balanceAndTransaction: '/api/balanceAndTransaction/getCurrentAccountBalance',
    currencyBalances: '/api/balanceAndTransaction/getCurrentAccountBalances',
    getLoanCurrencyBalance: '/api/loanBalance/getLoanCurrentBalance',
    getPreferences: '/api/payment/loan/getPreferences',
    loanPaymentAdd: '/api/payment/loanPayment/add',
    loanDrawAdd: '/api/payment/loanDraw/add',
    loanPaymentRead: '/api/payment/loanPayment/read',
    loanDrawRead: '/api/payment/loanDraw/read',
    loanPaymentUpdate: '/api/payment/loanPayment/update',
    loanDrawUpdate: '/api/payment/loanDraw/update',
    createPaymentFromTemplate: '/api/payment/listView/corp/createPaymentFromTemplate',
    addLoanPaymentTemplate: '/api/payment/loanPayment/add',
    addLoanDrawTemplate: '/api/payment/loanDraw/add',
    readLoanPaymentTemplate: '/api/payment/loanPayment/read',
    readLoanDrawTemplate: '/api/payment/loanDraw/read',
    updateLoanPaymentTemplate: '/api/payment/loanPayment/update',
    updateLoanDrawTemplate: '/api/payment/loanDraw/update',
    restoreLoanPaymentTemplate: '/api/payment/loanPayment/restore',
    restoreLoanDrawTemplate: '/api/payment/loanDraw/restore',
    loanReporting: '/api/loanreporting/getLoanCurrentBalance',
    bankAcctFiltersAssignAccounts: '/api/bankAcctFilters/clientUserGrpAcct/assignAccounts',
    bankAcctFiltersUnassignAccounts: '/api/bankAcctFilters/clientUserGrpAcct/unAssignAccounts',
    loanBalancesReporting: '/api/loanreporting/getLoanCurrentBalances',
    paymentTransferAdd: '/api/payment/transfer/add',
    paymentTransferRead: '/api/payment/transfer/read',
    paymentSMBListView: '/api/payment/listView/smb/getListView',
    paymentWireRead: '/api/payment/fedwire/read',
    paymentWireCreate: '/api/payment/fedwire/add',
    paymentWireCreateValidation: '/api/payment/fedwire/add/validateOnly',
    paymentWireUpdate: '/api/payment/fedwire/update',
    paymentWireUpdateValidation: '/api/payment/fedwire/update/validateOnly',
    billCreate: '/api/tableMaintenance/bill/add',
    billCreateValidation: '/api/tableMaintenance/bill/add/validateOnly',
    oAuthRequestToken: '/api/oauth/request_token',
    oAuthStatus: '/api/oauth/status',
    oAuthSetKey: '/api/oauth/oAuthKeys',
    smbBillListView: '/api/tableMaintenance/bill/getListView',
    smbBillEntitledActions: '/api/tableMaintenance/bill/getEntitledActions',
    smbBillUpdate: '/api/tableMaintenance/bill/update',
    smbMultiActions: '/api/payment/listView/corp',
    accountLookup: '/api/beneAddressBook/getLookupResults',
    beneAdressLookup: '/api/beneAddressBook/getLookupResults',
    advancedLookup: '/api/advanceFilter/getLookupResults',
    tableMaintLookup: '/api/tableMaintenance/getLookupResults',
    billPayImage: '/api/payment/billPay/getStatementImage',
    liveMaintenanceCheck: '/api/liveMaintenance/isMaintenanceMode',
    liveMaintenanceStart: '/api/liveMaintenance/startMaintenanceMode',
    liveMaintenanceStop: '/api/liveMaintenance/stopMaintenanceMode',
    templateGroups: '/api/tableMaintenance/PaymentTemplateGroups/getListView',
    alertsRecipients: '/api/alerts/detailMaint/getQueryResults',
    ssoCrudStub: '/api/userSelfService/ssoTpv/',
    updateUser: '/api/userMaintenance/user/update',
    resetPassword: '/api/userMaintenance/user/resetpw',
    panelAprovalWorkflow: '/api/panelApprovalWorkflow',
    panelAprovalWorkflowAdd: '/api/panelApprovalWorkflow/add',
    panelAprovalWorkflowRead: '/api/panelApprovalWorkflow/readPanelWorkflow',
    panelAprovalWorkflowUpdate: '/api/panelApprovalWorkflow/update',
    panelAprovalWorkflowApprove: '/api/panelApprovalWorkflow/approve',
    panelAprovalWorkflowDelete: '/api/panelApprovalWorkflow/delete',
    rdcDepositAccountsByLocationEntitlements: '/api/imaging/remoteDepositCapture/locationAccounts/getLocationAccounts',
    rdcDepositAccountsByEntitlements: '/api/imaging/remoteDepositCapture/getAllAccounts',
    rdcLocationList: '/api/imaging/remoteDepositCapture/locations/getListView',
    rdcSubmitDepositCheck: '/api/imaging/remoteDepositCapture/submitDepositCheck',
    rdcIsSystemAvailable: '/api/imaging/remoteDepositCapture/isSystemAvailable',
    rdcDepositHistory: '/api/imaging/remoteDepositCapture/getDepositHistory',
    rdcDepositItemImages: '/api/imaging/remoteDepositCapture/getDepositItemImages',
    serviceRequest: '/api/serviceRequest',
    serviceRequestNotesList: '/api/serviceRequest/notes/getListView',
    serviceRequestFreeForm: '/api/serviceRequest/freeform',
    checkInquiryListView: '/api/cm/listView/checkInquiry/getListView',
    stopPaymentAction: '/api/cminst/placeStop',
    balanceInquiryLoanListView: '/api/loanreporting/accountSummary/getListView',
    balanceInquiryDepositAccountListView: '/api/balanceAndTransaction/depositAccounts/ALLACCOUNTS/accountSummary/getListView',
    realTimePaymentsPreferences: '/api/tableMaintenance/rtpPreferences',
    analyticsServerSettings: '/api/systemMaintenance/analyticsServerConfig',
    keepAlive: '/api/security/keepAlive',
    createLogURL: '/api/analyticsServer/getSettings',
    checkInquiryURL: '/api/tableMaintenance/getQueryResults',
};

const typeServicesMap = {
    RTGS_INST_BPAY: '/payment/billPay',
    RTGS_INST_CHAPS: '/payment/CHAPSCustomer',
    RTGS_INST_DRAFT: '/payment/draft',
    RTGS_INST_DRAWDOWN: '/payment/drawdown',
    RTGS_INST_EURO: '/payment/Euro',
    RTGS_INST_FEDTAX: '/payment/fedtax',
    RTGS_INST_FEDWIRE: '/payment/fedwire',
    RTGS_INST_FILE: '/payment/File',
    RTGS_INST_INTL: '/payment/Wire-International',
    RTGS_INST_ITRNSFER: '/payment/itrnsfer',
    RTGS_INST_LOANDRAW: '/payment/loanDraw',
    RTGS_INST_LOANPAY: '/payment/loanPayment',
    RTGS_INST_MULTIBK: '/payment/multiBankRtgs',
    RTGS_INST_TRANSFER: '/payment/transfer',
    RTP_INST_CRTRAN: '/payment/crtran',
    USACH_BATCH_BDACHCC: '/batch/CashConcentration',
    USACH_BATCH_BDACHCCD: '/batch/CashConcentrationDisbursement',
    USACH_BATCH_BDACHCCP: '/batch/ConsumerCollectionsPayments',
    USACH_BATCH_BDACHCD: '/batch/CashDisbursement',
    USACH_BATCH_BDACHCEC: '/batch/CorporateCollections',
    USACH_BATCH_BDACHCP: '/batch/ConsumerPayments',
    USACH_BATCH_BDACHCRC: '/batch/ConsumerCollections',
    USACH_BATCH_BDACHCSP: '/batch/ChildSupportPayments',
    USACH_BATCH_BDACHCTX: '/batch/CorporateTradeExchange',
    USACH_BATCH_BDACHCVP: '/batch/CorporateVendorPayments',
    USACH_BATCH_BDACHDC: '/batch/DestroyedCheck',
    USACH_BATCH_BDACHFP: '/batch/FasterPayments',
    USACH_BATCH_BDACHIAT: '/batch/InternationalACHTransaction',
    USACH_BATCH_BDACHIIC: '/batch/InternetInitiatedCollections',
    USACH_BATCH_BDACHNFI: '/batch/NACHAFileImport',
    USACH_BATCH_BDACHP: '/batch/Payroll',
    USACH_BATCH_BDACHTIC: '/batch/TelephoneInitiatedCollections',
    USACH_BATCH_BDACHTP: '/batch/TaxPayments',
    USACH_BATCH_BDEFTCC: '/batch/eftDirectDeposit',
    USACH_BATCH_BDEFTCCD: '/batch/eftDirectDepositPreAuthDebit',
    USACH_BATCH_BDEFTCD: '/batch/eftPreAuthorizedDebit',
    USACH_BATCH_BIEFTCC: '/batch/eftDirectDepositImport',
    USACH_BATCH_BIEFTCCD: '/batch/eftDirectDepositPreAuthDebitImport',
    USACH_BATCH_BIEFTCD: '/batch/eftPreAuthorizedDebitImport',
};

/*
 * urls that are commonly only found on either
 * admin or client
 */
const staticUrls = {};

/*
 * iterate through the dynamicUrls and add them to the main
 * url package
 */
Object.keys(dynamicUrls).forEach((key) => {
    /*
     * some factor will later cause this to
     * replace some of the bases with the client
     * variant and some with the admin
     */
    if (typeof dynamicUrls[key] === 'string') {
        staticUrls[key] = serviceBase + dynamicUrls[key];
    }
});

Object.assign(staticUrls, typeServicesMap);

staticUrls.generateUrl = generateUrl;

staticUrls.generateWidgetUrl = function (path) {
    try {
        const appendedPath = (path.charAt(0) !== '/') ? `/${path}` : path;

        return `${window.Bottomline.documentRoot}/widget-services/api${appendedPath}`;
    } catch (e) {
        return `${window.Bottomline.documentRoot}/widget-services/api/`;
    }
};

staticUrls.generateServletUrl = function (path) {
    try {
        const appendedPath = (path.charAt(0) !== '/') ? `/${path}` : path;

        return `${window.Bottomline.documentRoot}/servlet-services/api${appendedPath}`;
    } catch (e) {
        return `${window.Bottomline.documentRoot}/servlet-services/api/`;
    }
};

staticUrls.generateAdminServletUrl = function (path) {
    try {
        const appendedPath = (path.charAt(0) !== '/') ? `/${path}` : path;

        return `${window.Bottomline.documentRoot}/admin-servlet-services/api${appendedPath}`;
    } catch (e) {
        return `${window.Bottomline.documentRoot}/admin-servlet-services/api/`;
    }
};

staticUrls.bases = serviceBases;

export default staticUrls;
