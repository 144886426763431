import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// module reducers
import { mdfReducers, mdfInitialState } from '@dbiqe/mdf';
import { commonReducer, commonInitialState } from '@dbiqe/common';
import { initialComboWrappersState, comboWrappersReducer } from '../ComboboxWrapper';
import { initialInteractionsState, interactionsReducer } from './reducers/interactions';
import { initialUserPreferencesState, userPreferencesReducer } from './reducers/userPreferences';
import { initialPageState, pageStateReducer } from './reducers/pageState';
import achWarehouseMiddleware from '../AchWarehouseInquiry/middleware/achWarehouseInquiry';

const initialState = {
    ...initialComboWrappersState,
    ...initialInteractionsState,
    ...initialPageState,
    ...mdfInitialState,
    ...commonInitialState,
    ...initialUserPreferencesState,
};

const coreReducer = combineReducers({
    ...mdfReducers,
    ...comboWrappersReducer,
    ...commonReducer,
    ...interactionsReducer,
    ...userPreferencesReducer,
    ...pageStateReducer,
});

// eslint-disable-next-line no-underscore-dangle
const reduxWindowCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// eslint-disable-next-line no-undef
const reduxDevToolsCompose = WEBPACK_IS_DEV && reduxWindowCompose ? reduxWindowCompose({
    trace: true,
    traceLimit: 25,
}) : reduxWindowCompose;

const composeEnhancers = reduxDevToolsCompose || compose;

export default (state = initialState) => createStore(
    coreReducer,
    state,
    composeEnhancers(applyMiddleware(thunk, achWarehouseMiddleware)),
);
