export default {
    // Blues
    'cornflower':                                  '#8dc8e8',
    'cerulean':                                    '#0090da',
    'royalBlue':                                   '#004f71',
    'astronautBlue':                               '#004163',

    // Greens
    'envy':                                        '#8cac89',
    'peppermint':                                  '#dff0d6',  
    
    // Yellows
    'zombie':                                      '#e3e48d',

    // Reds
    'brightRed':                                   '#ad0000',

    // Grays
    'white':                                       '#FFFFFF',
    'blackPearl':                                  '#041c2c',
    'silver':                                      '#cdcdcd',
    'mineShaft':                                   '#333333',
    'gallery':                                     '#ededed',
    'dustyGray':                                   '#999999',
    'secondSilver':                                '#cccccc',
};