import {
    appConfigsActions,
    configParamsActions,
    fieldActions,
    formActions,
    pageContextActions,
    userInfoActions,
} from '@dbiqe/mdf';
import { alertMessageActions } from '@dbiqe/common';
import { reduxStore } from 'components/StoreProvider/StoreProvider';
import { comboWrappersActions } from '../../components/ComboboxWrapper';
import { interactionsActions } from '../../components/StoreProvider/reducers/interactions';
import { userPreferencesActions } from '../../components/StoreProvider/reducers/userPreferences';
import { pageStateActions } from '../../components/StoreProvider/reducers/pageState';
import mobileUtil from '../../mobile/util/mobileUtil';

const getState = () => reduxStore.getState();

const setGridActionData = (actionData) => {
    const mode = (actionData.actionType || actionData.actionMode)?.toLowerCase();
    reduxStore.dispatch({
        type: pageContextActions.actionTypes.ADD_ACTION_DATA,
        payload: {
            mode,
            actionData,
        },
    });
};

const resetPageContext = () => {
    reduxStore.dispatch({
        type: pageContextActions.actionTypes.RESET_PAGECONTEXT,
        payload: {},
    });
};

/**
 * Update page context with the provided array of objects contain key value props.
 * @param {Object[]} data
 * @param {string} data[].key - name of key to use
 * @param {string} data[].value - value to set
 */
const updatePageContext = (data) => {
    reduxStore.dispatch(pageContextActions.updatePageContextData(data));
};

const setAppConfigs = (appConfigs = {}) => {
    reduxStore.dispatch({
        type: appConfigsActions.actionTypes.ADD_APP_CONFIGS,
        payload: {
            appConfigs,
        },
    });
};

const setConfigParams = (configParams = {}) => {
    reduxStore.dispatch({
        type: configParamsActions.actionTypes.ADD_CONFIG_PARAMS,
        payload: {
            configParams,
        },
    });
};

const setUserInfo = (userInfo = {}) => {
    reduxStore.dispatch({
        type: userInfoActions.actionTypes.ADD_USER_INFO,
        payload: {
            userInfo,
        },
    });
};
const addAlertMessage = (contextKey, { status, response, type }) => {
    reduxStore.dispatch({
        type: alertMessageActions.actionTypes.ADD_ALERT_MESSAGE,
        payload: {
            key: contextKey,
            status,
            response,
            type,
        },
    });
};
const removeAlertMessage = (contextKey) => {
    reduxStore.dispatch({
        type: alertMessageActions.actionTypes.REMOVE_ALERT_MESSAGE,
        payload: {
            key: contextKey,
        },
    });
};

const getAlertMessage = (contextKey) => {
    const state = getState();
    return state?.alertMessages?.[contextKey] || {};
};

const resetFormState = () => {
    reduxStore.dispatch({
        type: formActions.actionTypes.RESET,
        payload: {
            pageContextKeysToKeep: ['previousRoute', 'currentRoute'],
        },
    });
};

const updateRoutes = (route) => {
    reduxStore.dispatch({
        type: pageContextActions.actionTypes.SET_NEW_ROUTE,
        payload: {
            route,
        },
    });
};

/**
 * Set the baseModuleRoute in the pageContext. You should be doing this in the module.
 * See ComposedForm in @dbiqe/mdf for more details.
 * @deprecated
 */
const setBaseModuleRoute = () => {
    updatePageContext({
        key: 'baseModuleRoute',
        value: mobileUtil.isMobileGridEnabled()
            ? document.location.pathname + document.location.search
            : document.location.pathname,
    });
};

const setEntryRoute = () => {
    updatePageContext({
        key: 'entryRoute',
        value: mobileUtil.isMobileGridEnabled()
            ? document.location.pathname + document.location.search
            : document.location.pathname,
    });
};

const addComboWrappers = (payload = {}) => {
    reduxStore.dispatch({
        type: comboWrappersActions.ADD_COMBO_WRAPPERS,
        payload,
    });
};

const resetComboWrappers = () => {
    reduxStore.dispatch({
        type: comboWrappersActions.RESET_COMBO_WRAPPERS,
    });
};

const updateComboWrappers = (payload = {}) => {
    reduxStore.dispatch({
        type: comboWrappersActions.UPDATE_COMBO_WRAPPERS,
        payload,
    });
};

const addExcludedCombos = (payload = {}) => {
    reduxStore.dispatch({
        type: comboWrappersActions.ADD_EXCLUDED_COMBOS,
        payload,
    });
};

/*
 * Update can be used for both add and update actions
 */
const updateInteractions = (payload = {}) => {
    reduxStore.dispatch({
        type: interactionsActions.UPDATE_INTERACTIONS,
        payload,
    });
};

const resetInteractions = () => {
    reduxStore.dispatch({
        type: interactionsActions.RESET_INTERACTIONS,
    });
};

const savePageState = (payload = {}) => {
    reduxStore.dispatch({
        type: pageStateActions.SAVE_PAGE_STATE,
        payload,
    });
};

const resetPageState = (payload = {}) => {
    reduxStore.dispatch({
        type: pageStateActions.RESET_PAGE_STATE,
        payload,
    });
};

const setUserPreferences = (payload = {}) => {
    reduxStore.dispatch({
        type: userPreferencesActions.SET_USER_PREFERENCES,
        payload: {
            userPreferences: payload,
        },
    });
};

const updateUserInfo = (payload = {}) => {
    reduxStore.dispatch({
        type: userInfoActions.actionTypes.UPDATE_USER_INFO,
        payload: {
            userPreferences: payload,
        },
    });
};

/**
 * @param {Object} payload Contains { formName, fieldUpdates }
 * fieldUpdates: [{
 *   fieldName: bene_country,
 *   properties: [{ key: 'mandatory', value: true }, { key: 'protected', value: false}]
 * }]
 */
const updateFormFieldMultiple = (payload = {}) => {
    reduxStore.dispatch({
        type: fieldActions.actionTypes.CHANGE_MULTIPLE_FIELDS,
        payload,
    });
};

export {
    getState,
    setAppConfigs,
    setConfigParams,
    setGridActionData,
    resetPageContext,
    setUserInfo,
    removeAlertMessage,
    addAlertMessage,
    getAlertMessage,
    resetFormState,
    setBaseModuleRoute,
    setEntryRoute,
    updatePageContext,
    updateRoutes,
    addComboWrappers,
    resetComboWrappers,
    updateComboWrappers,
    addExcludedCombos,
    updateInteractions,
    resetInteractions,
    setUserPreferences,
    savePageState,
    resetPageState,
    updateUserInfo,
    updateFormFieldMultiple,
};

export default reduxStore.getState();
