import palette from '../palette';

export default {
    label: {
        color: palette.mindShaft
    },

    checkboxLabel: { 
        color: palette.mindShaft
    }
};