/*
 * Required minimum config params for each client:
 * 1. ExternalDispatchSnippetURL - a URL for the snippet
 * 2. ExternalDispatchCallbackName - the name of a function that the snippet invokes
 * 3. ExternalDispatchGlobalFunctionName - the name of a function we invoke exposed by the snippet
 *
 * Route Tags - branding override
 * Depending on the implementation, different route tags may need to be overridden by branding.
 * Overriding the string pairs in externalDispatchRouteContext.js will update the routes
 *  consumed by routeChange
 */

import util from 'underscore';
import Glu from '@dbiqe/glu-core/src/glu';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import externalDispatchRouteContext from './externalDispatchRouteContext';
import getSession, { isScriptInjected } from './util';

/**
 * Get the tag name for a given route
 * @param {string} route | route to get tag name for
 * @returns string
 */
const getContext = route => externalDispatchRouteContext[route] || 'other';

/**
 * Get the config object
 * @returns {Object} config object
 */
const getConfig = () => ({
    externalDispatchIncludeGroup: serverConfigParams.get('ExternalDispatchIncludeGroup'),
    externalDispatchCallbackName: serverConfigParams.get('ExternalDispatchCallbackName'),
    externalDispatchDefaultTag: serverConfigParams.get('ExternalDispatchDefaultTag'),
    externalDispatchGlobalFunctionName: serverConfigParams.get('ExternalDispatchGlobalFunctionName'),
    externalDispatchMode: serverConfigParams.get('ExternalDispatchMode'),
    externalDispatchSessionCookieName: serverConfigParams.get('ExternalDispatchSessionCookieName'),
    externalDispatchDisableCSID: serverConfigParams.get('ExternalDispatchDisableCSID'),
    externalDispatchSnippetUrl: serverConfigParams.get('ExternalDispatchSnippetURL'),
});

/**
 * Get the URL for the snippet
 * @returns {string} URL for snippet
 */
const generateSnippetUrl = () => {
    const {
        externalDispatchDefaultTag,
        externalDispatchSnippetUrl,
    } = getConfig();
    return `${externalDispatchSnippetUrl}?dt=${externalDispatchDefaultTag ||
        getContext(window.location.pathname)}&r=${Math.random()}`;
};

/**
 * Unified callback function
 * @param {Object} param0
 * @param {Object} param0.mode - whether in 'preauth' or 'postauth'
 * @returns
 */
const unifiedCallback = ({ mode, userInfo }) => () => {
    const {
        externalDispatchIncludeGroup,
        externalDispatchSessionCookieName,
        externalDispatchDisableCSID,
    } = getConfig();
    return {
        // minimum required is session info
        ...(externalDispatchDisableCSID === 'true' ?
            {} : { c: getSession(externalDispatchSessionCookieName) }),
        // if postauth, then include the user info
        ...(mode === 'postauth' ? {
            u: {
                p: [
                    ...(externalDispatchIncludeGroup === 'true' ? [userInfo.get('group')] : []),
                    userInfo.get('id'),
                ],
            },
            // if we have a permanent user id, include it
            ...(userInfo?.get('permanentUserId') ? {
                p: {
                    p: userInfo.get('permanentUserId'),
                },
            } : {}),
        } : {}),
    };
};

/**
 * Append the snippet to the head of the document
 * @returns {void}
 */
const appendSnippet = () => {
    // if the script is already injected, don't inject it again
    const {
        externalDispatchSnippetUrl,
    } = getConfig();
    const snippetUrl = generateSnippetUrl();
    if (!isScriptInjected(externalDispatchSnippetUrl)) {
        const f = document;
        const e = window;
        const i = e.location.protocol;
        const b = [
            ['src', [i === 'https:' ? 'https:/' : 'http:/', snippetUrl].join('/')],
            ['type', 'text/javascript'],
            ['async', true],
        ];
        const c = f.createElement('script');
        const h = f.getElementsByTagName('head')[0];
        setTimeout(() => {
            // eslint-disable-next-line no-plusplus
            for (let d = 0, l = b.length; d < l; d++) {
                c.setAttribute(b[d][0], b[d][1]);
            }
            h.appendChild(c);
        }, 0);
    }
};

const externalDispatch = {
    postAuth(userInfo) {
        const {
            externalDispatchCallbackName,
            externalDispatchGlobalFunctionName,
            externalDispatchSnippetUrl,
        } = getConfig();

        if (util.isEmpty(externalDispatchSnippetUrl)) {
            return;
        }

        // Some integrations do not require this callback
        if (!util.isEmpty(externalDispatchCallbackName)) {
            // Make sure on postauth the callback includes user info
            window[externalDispatchCallbackName] = unifiedCallback({
                mode: 'postauth',
                userInfo,
            });
        }

        appendSnippet();

        // fire off snippet for initial page load
        if (typeof window[externalDispatchGlobalFunctionName] === 'function') {
            window[externalDispatchGlobalFunctionName](getContext(document.location.pathname));
        }
    },

    preAuth() {
        const {
            externalDispatchCallbackName,
            externalDispatchGlobalFunctionName,
            externalDispatchMode,
            externalDispatchSnippetUrl,
        } = getConfig();

        if (util.isEmpty(externalDispatchSnippetUrl) || externalDispatchMode !== 'preauth') {
            return;
        }

        // Some externalDispatch integrations do not require this callback
        if (!util.isEmpty(externalDispatchCallbackName)) {
            window[externalDispatchCallbackName] = unifiedCallback({ mode: 'preauth' });
        }

        appendSnippet();

        // fire off login snippet
        if (typeof window[externalDispatchGlobalFunctionName] === 'function') {
            window[externalDispatchGlobalFunctionName]('login');
        }
    },

    /**
     * When the route changes, invoke the global function created by the
     * snippet, passing in the route tag expected
     * @param {*} route
     */
    routeChange(route) {
        const {
            externalDispatchGlobalFunctionName,
        } = getConfig();
        // NH-150501 Prevent callback from being invoked on logout
        if (route.endsWith('logout')) {
            return;
        }
        // Redirect is a route change that occurs when going from login screen to the application
        if (Glu.history.fragment === 'redirecting') {
            return;
        }
        if (typeof window[externalDispatchGlobalFunctionName] !== 'function') {
            return;
        }
        window[externalDispatchGlobalFunctionName](getContext(route));
    },
};

export default externalDispatch;
