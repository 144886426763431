import palette from '../palette';

export default {
    fontFamily: 'Avenir, Roboto, Helvetica, Arial, sans-serif',

    primary: {
        backgroundColor: palette.astronautBlue,
        borderColor: palette.astronautBlue,
        focusColor: palette.blackPearl,
        hoverColor: palette.blackPearl,
        textColor: palette.white,
        disabledBackground: palette.silver,
        disabledBorder: palette.silver,
        disabledColor: palette.dustyGray,
        '&:hover': {
            backgroundColor: palette.blackPearl,
            borderColor: palette.blackPearl,
            color: palette.white,
        }
    },

    secondary: {
        backgroundColor: palette.white,
        borderColor: palette.astronautBlue,
        focusColor: palette.astronautBlue,
        hoverColor: palette.astronautBlue,
        textColor: palette.astronautBlue,
        disabledBackground: palette.silver,
        disabledColor: palette.dustyGray,
        disabledBorder: palette.silver,
        '&:hover': {
            backgroundColor: palette.astronautBlue,
            borderColor: palette.astronautBlue,
            color: palette.white,
        }
    },

    tertiary: {
        textColor: palette.astronautBlue,
        '&:hover': {
            color: palette.blackPearl,
        },
        '&.disabled': {
            color: palette.silver
        }
    },

    extraSmall: {
        borderRadius: 4
    },

    normal: {
        borderRadius: 4
    }
};